import React from 'react'
import Skeleton from "react-loading-skeleton";

function CityActivitySkeleton() {
  return (
    <div className="custContain citySkeletontop">
           <div className='ncitySkeletonTitle'><Skeleton  height={63} width={"40%"} st/></div> 
    <div className="row activity-skeleton-container citySkeleton">

      {Array(8)
        .fill()
        .map((_,i) => {
          return (
            <div className="col-xl-3 col-lg-4 col-md-6 col-8 " key={i}>
              <div className="activity-skeleton">
                <Skeleton  height={200} width={"100%"} st/>
                <div className="activity-detail-skeleton">
                  <Skeleton count={1} height={20} width={"50%"} />
                  <Skeleton count={1} height={15} width={"100%"} />
                  <Skeleton count={1} height={15} width={"100%"} />
                  <Skeleton count={1} height={15} width={"30%"} />
                  <Skeleton count={1} height={15} width={"40%"} />
                  <Skeleton count={1} height={15} width={"50%"} />
                </div>
              </div>
            </div>
          );
        })}
    </div>
  </div>
  )
}

export default CityActivitySkeleton