import React from 'react'
import Skeleton from "react-loading-skeleton";

function MiniblogSkeleton() {
  return (
    <div className="custContain " style={{marginLeft:"50px",marginRight:"50px"}} >

              <div className="activity-skeleton">
                <Skeleton  height={400} width={"100%"} st/>
               
              </div>
     
  </div>
  )
}

export default MiniblogSkeleton