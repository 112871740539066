import React, { useEffect, useState } from "react";
import AuthService from "../Service/AuthService";
import HttpClient from "../utils/HttpClient";
import { userStorage } from "../utils/localStorage";
const authContext = React.createContext();

export const useAuth = () => React.useContext(authContext);

const AuthContextProvider = ({ children }) => {
  const [loginStatus, setLoginStatus] = useState(
    () => localStorage.getItem("login") || false
  );
  const [userData, setUserData] = useState(null);
  const [isChecking, setIsChecking] = useState(false);
  const [showSignUpModal, setShowSignUpModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [fetchedActivityTypes, setFetchedActivityTypes] = useState([]);
  const [amIfromAddtoCart, setamIfromAddtoCart] = useState(false);
  const [cartvaluedata, setcartvaluedata] = useState(null);

  const getGuestUser = async () => {
    try {
      let res = await HttpClient.requestData("v1/user/guest");
      if (res && res.status) {
        userStorage.setItem({ name: "guest", id: res.data._id });
      } else {
      }
    } catch (error) {}
  };

  const fetchUser = async () => {
    let loginStatus = localStorage.getItem("login");
    if (!loginStatus) return;

    try {
      setIsChecking(true);
      const userRes = await AuthService.getProfile();

      if (userRes && userRes?.status) {
        setamIfromAddtoCart(true);
        setLoginStatus(true);
        setUserData(userRes?.data?.[0]);
        setIsChecking(false);
        localStorage.setItem(
          "loggedUserData",
          JSON.stringify(userRes?.data?.[0])
        );
        return;
      } else {
        setIsChecking(false);
        userStorage.removeItem()
        getGuestUser()
      }
    } catch (error) {
      setIsChecking(false);
      userStorage.removeItem()
      getGuestUser()

    }

    setUserData(null);
    localStorage.clear();
    setLoginStatus(false);
  };

  const fetchActivityTypes = async () => {
    try {
      let res = await HttpClient.requestData("v1/user/viewActivityType");
      if (res && res.status) {
        setFetchedActivityTypes(res?.data);
      } else {
      }
    } catch (error) {}
  };

  useEffect(() => {
    fetchUser();
  }, [loginStatus]);



  useEffect(() => {
    //  console.log(userStorage.getItem())
    if (!userStorage.getItem()) getGuestUser();
  }, []);

  return (
    <authContext.Provider
      value={{
        loginStatus,
        setLoginStatus,
        userData,
        fetchUser,
        isChecking,
        showSignUpModal,
        setShowSignUpModal,
        showLoginModal,
        setShowLoginModal,
        fetchActivityTypes,
        fetchedActivityTypes,
        amIfromAddtoCart,
        setamIfromAddtoCart,
        cartvaluedata,
        setcartvaluedata,
      }}
    >
      {children}
    </authContext.Provider>
  );
};

export default AuthContextProvider;
