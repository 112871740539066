import { useContext, createContext, useState, useEffect } from 'react';
import { deleteCartItem, deleteGiftCartItem, getCartData } from '../API_HELPERS/apiHelpers';
import { toast } from 'react-hot-toast';
import { useAuth } from './AuthContextProvider';
import { userStorage } from '../utils/localStorage';

const cartContext = createContext({});
export const useCartContext = () => useContext(cartContext);

const CartProvider = ({ children }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [cart, setCart] = useState({cart_activity:[],cart_giftcard:[]});
  const { loginStatus } = useAuth();
  const fetchCartData = async () => {
    if(!userStorage.getItem()) return
    try {
      setIsLoading(true);
      const res = await getCartData(userStorage.getItem().id);
      if (res && res?.status) {
         
        setCart(res?.data);
        setIsLoading(false);
        
      } else {
        setIsLoading(false);
      }
    } catch (error) {
      setIsLoading(false);
    }
    setIsLoading(false);
  };

  const removeCartItem = async id => {
  
    try {
      setIsLoading(true);
      const res = await deleteCartItem(id);
      if (res && res?.status) {
        toast.success('Activity removed from cart successfully');
        fetchCartData();
      } else {
        toast.error(res?.message || 'Something went wrong');
      }
    } catch (error) {
      toast.error(error?.message || 'Something went wrong');
    }
    setIsLoading(false);
  };

  const removeGiftCartItem = async id => {
  
    try {
      setIsLoading(true);
      const res = await deleteGiftCartItem(id);
      if (res && res?.status) {
        toast.success('Gift Card removed successfully');
        fetchCartData();
      } else {
        toast.error(res?.message || 'Something went wrong');
      }
    } catch (error) {
      toast.error(error?.message || 'Something went wrong');
    }
    setIsLoading(false);
  };

  useEffect(() => {
    // if(loginStatus){
    fetchCartData();
    // }
  }, [loginStatus]);

  return (
    <cartContext.Provider
      value={{
        cart,
        setCart,
        fetchCartData,
        removeCartItem,
        removeGiftCartItem,
        isLoading,
        setIsLoading
       
      }}
    >
      {children}
    </cartContext.Provider>
  );
};

export default CartProvider;
