import React, { useCallback, useState } from "react";
import HomeService from "../Service/HomeService";

const homeContext = React.createContext();

export const useHome = () => React.useContext(homeContext);

function HomeContext({ children }) {
  const [isLoading, setIsLoading] = useState(false);
  const [currentTabId, setCurrentTabId] = useState("");
  const [activityType, setActivityType] = useState([]);
  const fecthActivityType = useCallback(() => {
    HomeService.activityType().then((res) => {
      if (res && res.status) {
        setActivityType(res.data);
      }
    });
  }, []);

  return (
    <homeContext.Provider
      value={{
        activityType,
        fecthActivityType,
        setCurrentTabId,
        currentTabId,
        isLoading,
        setIsLoading,
      }}
    >
      {children}
    </homeContext.Provider>
  );
}

export default HomeContext;
