import React from 'react'
import MiniblogSkeleton from './MiniblogSkeleton'
import AttractionLinkSkeleton from './AttractionLinkSkeleton'
import Skeleton from 'react-loading-skeleton'
import CityActivitySkeleton from './CityActivitySkeleton'

function SearchCitySkeleton() {
  return (
    <div>
           <div style={{marginBottom:"20px"}}>
                <Skeleton height={500} width={"100%"} st/>
               
              </div>
        <CityActivitySkeleton/>
        <MiniblogSkeleton/>
        <AttractionLinkSkeleton/>
      
    </div>
  )
}

export default SearchCitySkeleton
