import React from "react";
import "../src/view/CulturalExperienceDetails/CulturalExperience.css";
import "./view/Wishlist/WishlistStyle.css";
import "./view/MakePayment/MakePayment.css";
import "./view/BookingHistory/BookingHistory.css";
import "./view/Contact/Contact.css";
import "./App.css";
import "./view/PrivacyPolicy/privacyPolicy.css";
import "./view/SiteMap/siteMap.css";
import "./view/Information/information.css";
import "./view/Cookies/cookies.css";
import "./view/Magazine/magazine.css";
import "./view/TravelGuides/travelGuides.css";
import "yet-another-react-lightbox/styles.css";
import "./responsive.css";
import AppRouter from "./routing/routers";
import HomeContext from "./Context/HomeContext";

const App = () => {
  return (
    <HomeContext>
      <AppRouter />
    </HomeContext>
  );
};

export default App;
